<template>
  <h1 class="text-center text-danger">
    Reports
  </h1>
</template>

<script>

export default {
  name: 'Reports',
  created() {
    this.$emit('updateMenu', 'home-reports-list')
  },
}
</script>

<style scoped>

</style>
